import { Box, BoxProps, Flex, Text, useMediaQuery } from '@chakra-ui/core'
import { AuthContext } from '@pangaea-holdings/pangaea-auth'
import {
  CheckoutContext,
  CurrencySelect,
  updateCurrency,
  useCurrency,
  LanguageSelect,
} from '@pangaea-holdings/pangaea-checkout'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import cartIconWite from '../../../../../public/img/cart-icon-white.png'
import cartIcon from '../../../../../public/img/cart-icon.png'
import { debounce } from '../../../../utils/debounce'
import { useIsMountedRef } from '../../../../utils/hooks'
import { removeQueryParam } from '../../../../utils/removeQueryParam'
import AccountSidebarContainer from '../../../account/components/AccountSidebar/AccountSidebarContainer'
import { useLanguage, useRTL, useChangeLanguage } from '../../../i18n/hooks'
import ProductsSearchDrawerContainer from '../../../products/components/PLPElements/ProductsFilterSection/ProductsSearchDrawerContainer'
import { MagnifyingGlassIcon } from '../../../shared/components/Icons/MagnifyingGlassIcon'
import { SearchIcon } from '../../../shared/components/Icons/SearchIcon'
import { ShoppingCartIcon } from '../../../shared/components/Icons/ShoppingCartIcon'
import { UserIcon } from '../../../shared/components/Icons/UserIcon'
import ShopifyNextLink from '../../../shared/components/ShopifyNextLink'
import { NavTab } from '../../types'
import LeftNav from '../LeftNav'
import TopHoverNav from '../TopHoverNav'
import AnnouncementBar from './AnnouncementBar'
import NavItem from './NavItem'

import { CartDrawer } from 'modules/cartv2/components/Cart/CartDrawer'
import useInitializeCart from 'modules/cartv2/hooks/useInitializeCart'
import { useIsFreeTrialDisabled } from 'modules/freeTrial/hooks/useIsFreeTrialDisabled'
import { SUPPORTED_LANGUAGES } from 'modules/i18n/config'
import { LuminDarkIcon } from 'modules/lumin/components/Icons/LuminDarkIcon'
import { LuminLightIcon } from 'modules/lumin/components/Icons/LuminLightIcon'

const Hamburger = ({
  color = 'nightGreen',
  ...props
}: BoxProps & { display: string[]; onClick: () => void }) => (
  <Box
    as="button"
    aria-label="Navigation Menu"
    backgroundColor="transparent"
    border="none"
    width="27px"
    position="relative"
    cursor="pointer"
    p={0}
    {...props}
  >
    {[...Array(3)].map((_v, index) => (
      <Text
        key={index}
        height="1px"
        display="block"
        backgroundColor={color}
        mb={1}
      />
    ))}
  </Box>
)

export interface TopNavProps {
  theme?: 'light' | 'dark'
  hasBorder?: boolean
  hasBoxShadow?: boolean
  lightTopNav?: boolean
  showAnnouncementBar?: boolean
  announcementBarText?: string
  hideTopNav?: boolean
}

const TopNav: React.FC<TopNavProps> = ({
  theme = 'light',
  hasBorder = true,
  hasBoxShadow = false,
  lightTopNav = false,
  announcementBarText,
  showAnnouncementBar = true,
  hideTopNav = false,
}) => {
  const { t } = useTranslation()
  const { isRTL } = useRTL()
  const { language } = useLanguage()
  const { state, dispatch } = useContext(CheckoutContext)
  const { state: authState } = useContext(AuthContext)
  const [accountSidebarOpen, setAccountSidebarOpen] = useState<boolean>(false)
  const [accountSidebarShowSignup, setAccountSidebarShowSignup] =
    useState<boolean>(false)
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState(false)
  const [leftNavOpen, setLeftNavOpen] = useState(false)
  const [selectedNavTab, setSelectedNavTab] = useState<NavTab>(NavTab.SHOP)
  const [isCartShown, setIsCartShown] = useState(false)
  const [isSticky, setSticky] = useState(false)
  const [isBannerShown, setBannerShown] = useState(false)
  const isMounted = useIsMountedRef()
  const [openMenuNav, setOpenMenuNav] = useState<null | NavTab>(null)
  const isFreeTrialDisabled = useIsFreeTrialDisabled()
  const changeLanguage = useChangeLanguage()
  const [isDesktop] = useMediaQuery('(min-width: 1025px)')

  const cartItemsCount = state.cart.cart?.items?.reduce((total, item) => {
    return total + item.quantity
  }, 0)

  const router = useRouter()

  const accountSideBarQuery = router.query['account-menu']

  const currency = useCurrency()

  useInitializeCart()

  useEffect(() => {
    const { authModal } = router.query

    if (authModal === 'true') {
      if (authState.isAuthenticated) {
        setAccountSidebarOpen(true)
      }
      removeQueryParam('authModal', router)
    }
  }, [router.query, authState.isLoggedIn])

  useEffect(() => {
    const { redirect } = router.query
    if (redirect === 'login') {
      setAccountSidebarOpen(true)
    }
  }, [router.query.redirect])

  useEffect(() => {
    const { signup } = router.query

    if (signup === 'true') {
      setAccountSidebarOpen(true)
      setAccountSidebarShowSignup(true)
      removeQueryParam('signup', router)
    }
  }, [router.query.signup])

  useEffect(() => {
    if (accountSideBarQuery === 'true') {
      setAccountSidebarOpen(true)
      removeQueryParam('account-menu', router)
    }
  }, [accountSideBarQuery])

  useEffect(() => {
    const debouncedHandleScroll = debounce(() => {
      if (isMounted.current) {
        setSticky(window.scrollY >= 70)
      }
    }, 10)

    window.addEventListener('scroll', debouncedHandleScroll)

    return () => {
      window.removeEventListener('scroll', () => debouncedHandleScroll)
    }
  }, [])

  const openLeftNav = (navTab: NavTab) => {
    if (!isDesktop) {
      setSelectedNavTab(navTab)
      setLeftNavOpen(true)
    }
  }

  useEffect(() => {
    const openCartParam = router.query.openCart as string

    if (openCartParam === 'true') {
      removeQueryParam('openCart', router)
      showCart()
    }
  }, [router.query])

  const showCart = () => {
    setIsCartShown(true)
  }

  const openHoverNav = (tab: NavTab) => {
    if (isDesktop) {
      setOpenMenuNav(tab)
    }
  }

  const closeHoverNav = () => {
    if (isDesktop) {
      setOpenMenuNav(null)
    }
  }

  const onCloseProductSearchDrawer = () => {
    setIsSearchDrawerOpen(false)
  }

  const handleCurrencyChange = (currency: string) => {
    dispatch(updateCurrency(currency))
  }

  return (
    <>
      <Box
        top="0"
        id="top-nav-container"
        className={isBannerShown ? 'has-header-banner' : ''}
        width="100%"
        position={isSticky ? 'fixed' : 'absolute'}
        backgroundColor={['charcoal', null, 'white']}
        zIndex={'sticky'}
        boxShadow={hasBoxShadow || isSticky ? 'topNavShadow' : 'none'}
        display={hideTopNav ? 'none' : 'block'}
      >
        {showAnnouncementBar && (
          <AnnouncementBar
            text={announcementBarText}
            setBannerShown={setBannerShown}
          />
        )}
        <Flex
          direction="row"
          align="center"
          justify="space-between"
          height="64px"
          px={hasBorder ? [5, 5, 0] : [5, 5, 10]}
          mx={hasBorder ? [0, 0, 10] : 0}
          pt={2}
          color={[
            'offwhite',
            'offwhite',
            ...[theme === 'light' || isSticky ? 'black' : 'offwhite'],
          ]}
          borderBottom={['none', ...(hasBorder && !isSticky ? '1px' : 'none')]}
          borderColor={theme === 'light' ? 'topNavDarkBorder' : 'white'}
          overflow="hidden"
        >
          <Flex display={['none', 'none', 'flex']}>
            <NavItem
              onMouseEnter={() => openHoverNav(NavTab.SHOP)}
              data-test-selector="home-shop-button"
            >
              {t('nav:shop', 'Shop')}
            </NavItem>
            <NavItem
              href="/pages/customer-reviews"
              onMouseEnter={() => closeHoverNav()}
              data-test-selector="home-reviews-button"
            >
              {t('nav:reviews', 'Reviews')}
            </NavItem>
            <NavItem
              href="/pages/how-to"
              onMouseEnter={() => closeHoverNav()}
              data-test-selector="home-how-to-button"
            >
              {t('common:how-to', 'How To')}
            </NavItem>
            {isFreeTrialDisabled === false && (
              <NavItem
                href={'/pages/why-men-love-lumin?startQuiz=true'}
                onMouseEnter={() => closeHoverNav()}
                data-test-selector="home-quiz-button"
              >
                {t('nav:get-a-free-trial', 'Get a Free Trial')}
              </NavItem>
            )}
          </Flex>
          <ShopifyNextLink display={['none', 'none', 'inline-block']} href="/">
            {theme === 'light' || isSticky ? (
              <LuminDarkIcon fill="blackTitleColor" w="200px" h="100px" />
            ) : (
              <LuminLightIcon w="200px" h="100px" />
            )}
          </ShopifyNextLink>
          <Flex align="center" mt={-1} display={['none', null, 'flex']}>
            <NavItem
              mr={0}
              onClick={() => setIsSearchDrawerOpen(!isSearchDrawerOpen)}
              data-test-selector="home-search-button"
            >
              <SearchIcon fill="none" w="24px" h="24px" py={0} />
            </NavItem>
            <NavItem
              mr={0}
              onClick={() => setAccountSidebarOpen(true)}
              data-test-selector="home-account-button"
            >
              <UserIcon fill="none" w="24px" h="24px" py={0} />
            </NavItem>
            <Flex
              as="a"
              align="center"
              onClick={showCart}
              ml={isRTL ? 4 : 0}
              dir="ltr"
              data-test-selector="home-çart-button"
            >
              <Image
                alt="cart icon"
                src={theme === 'light' || isSticky ? cartIcon : cartIconWite}
                width={25}
                height={21}
                layout="fixed"
              />
              <Text width="13px" fontSize="sm" ml="1px" mb={3}>
                {cartItemsCount !== null && cartItemsCount}
              </Text>
            </Flex>

            <CurrencySelect
              value={currency}
              onChange={handleCurrencyChange}
              bgColor="transparent"
              border="none"
              _focus={{ border: 'none' }}
              ml={4}
              minW="84px"
              fontSize="1rem"
            />
            <LanguageSelect
              onChange={changeLanguage}
              value={language}
              ml={isRTL ? 0 : 6}
              border="none"
              w="30px"
              m={0}
              languages={SUPPORTED_LANGUAGES}
            />
          </Flex>
          <Flex
            display={['flex', null, 'none']}
            justify="space-between"
            align="center"
            w="100%"
          >
            <Flex justify="center" align="center">
              <Hamburger
                mr={2}
                display={['block', 'block', 'none']}
                color={[
                  'offwhite',
                  null,
                  ...[
                    isSticky || theme === 'light' ? 'nightGreen' : 'offwhite',
                  ],
                ]}
                onClick={() => openLeftNav(NavTab.SHOP)}
              />
              <NavItem
                mr={0}
                mt={-1}
                onClick={() => setIsSearchDrawerOpen(!isSearchDrawerOpen)}
              >
                <MagnifyingGlassIcon fill="none" w="24px" h="24px" py={0} />
              </NavItem>
            </Flex>
            <ShopifyNextLink href="/">
              <LuminLightIcon w="150px" h="100px" />
            </ShopifyNextLink>
            <Flex align="center" mt={-1} display={['flex', null, 'none']}>
              <LanguageSelect
                w="70px"
                minW="70px"
                border="none"
                onChange={changeLanguage}
                value={language}
                languages={SUPPORTED_LANGUAGES}
              />
              <Flex
                as="a"
                align="center"
                onClick={showCart}
                ml={isRTL ? 4 : 0}
                mr={!isRTL ? 4 : 0}
                dir="ltr"
              >
                <ShoppingCartIcon fill="none" w="24px" h="24px" py={0} />
                <Text width="13px" fontSize="sm" ml="1px" mb={3}>
                  {cartItemsCount !== null && cartItemsCount}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>

      <ProductsSearchDrawerContainer
        isOpen={isSearchDrawerOpen}
        onClose={onCloseProductSearchDrawer}
      />
      <TopHoverNav
        isOpen={!!openMenuNav}
        onClose={closeHoverNav}
        selectedNavTab={openMenuNav}
      />
      <AccountSidebarContainer
        isOpen={accountSidebarOpen}
        setIsOpen={setAccountSidebarOpen}
        showSignup={accountSidebarShowSignup}
      />
      <CartDrawer isOpen={isCartShown} setIsOpen={setIsCartShown} />
      <LeftNav
        isOpen={leftNavOpen}
        setIsOpen={setLeftNavOpen}
        leftNavTab={selectedNavTab}
        setLeftNavTab={setSelectedNavTab}
      />
    </>
  )
}

export default TopNav
