export const CLEARANCE_ITEM_MAP = {
  '39': {
    id: 39,
  },
  '40': {
    id: 40,
  },
  '42': {
    id: 42,
  },
  '43': {
    id: 43,
    variants: {
      sizes: [203, 204, 210],
    },
  },
  '44': {
    id: 44,
    variants: {
      sizes: [207, 208, 209],
    },
  },
  '46': {
    id: 46,
  },
  '48': {
    id: 48,
    variants: {
      Color: [200],
    },
  },
  '80': {
    id: 80,
  },
  '81': {
    id: 81,
  },
  '82': {
    id: 82,
  },
  '83': {
    id: 83,
  },
  '84': {
    id: 84,
  },
  '85': {
    id: 85,
  },
  '90': {
    id: 90,
  },
  '92': {
    id: 92,
  },
  '97': {
    id: 97,
  },
  '98': {
    id: 98,
  },
  '101': {
    id: 101,
  },
  '108': {
    id: 108,
  },
  '111': {
    id: 111,
  },
  '116': {
    id: 116,
  },
  '132': {
    id: 132,
  },
  '133': {
    id: 133,
  },
  '143': {
    id: 143,
  },
}
