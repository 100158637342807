import React from 'react'
import dynamic from 'next/dynamic'
import { AccountSidebarProps } from './AccountSidebar'

const AccountSidebar = dynamic(
  () => import('./AccountSidebar').then((mod) => mod.AccountSidebar),
  { ssr: false }
)

export interface AccountSidebarContainerProps {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  showSignup: boolean
}

const AccountSidebarContainer: React.FC<AccountSidebarProps> = ({
  isOpen,
  setIsOpen,
  showSignup,
}) => {
  return (
    <AccountSidebar
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      showSignup={showSignup}
    />
  )
}

export default AccountSidebarContainer
