import {
  CartResponse,
  removeCookie,
  setTrackingFunctions,
} from '@pangaea-holdings/pangaea-checkout'
import dynamic from 'next/dynamic'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Drawer } from '../../../../design/components'
import * as track from '../../../../modules/analytics/functions/track'
import { useLanguage } from '../../../i18n/hooks'
import { useVatConfig } from '../../../vat/hooks'
import MakeItASubscriptionModal from '../MakeItASubscriptionModal'

import { isClearanceCartItem } from 'modules/cartv2/functions'
import { hiddenCustomizationOptions } from 'modules/checkout/constants/products'
import { isValidDiscountCode } from 'modules/checkout/validDiscountCodes'
import { useFeatureFlag } from 'modules/optimizely/hooks/useFeatureFlag'
import { useIdsToShowLowStockBadge } from 'modules/products/hooks/useIdsToShowLowStockBadge'
import { toggleZendeskChatButtonDisplay } from 'modules/shared/hooks/useZendeskChat'
import { PROMOCODE_COOKIE } from 'utils/constants'

export interface CartDrawerProps {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
}

const CartContents = dynamic(
  () =>
    import('@pangaea-holdings/pangaea-checkout').then(
      (mod) => mod.CartContents
    ),
  { ssr: false }
)

export const CartDrawer: FC<CartDrawerProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation()

  const [showConvertToSubModal, setShowConvertToSubModal] =
    React.useState(false)

  const enableAvailablePaymentMethods = useFeatureFlag(
    'ENABLE_CART_PAYMENT_METHODS',
    false
  )

  const enableConvertCartToSubscriptionPrompt = useFeatureFlag(
    'ENABLE_CONVERT_CART_TO_SUBSCRIPTION_PROMPT',
    false
  )

  const { language } = useLanguage()

  const [PreCheckoutModal, setPreCheckoutModal] = useState<React.FC<{
    isOpen: boolean
    setIsOpen: (val: boolean) => void
  }> | null>(null)

  const vatConfig = useVatConfig()
  const idsToShowLowStockBadge = useIdsToShowLowStockBadge()

  useEffect(() => {
    toggleZendeskChatButtonDisplay(isOpen ? 'hide' : 'show')
  }, [isOpen])

  useEffect(() => {
    setTrackingFunctions(track)
  }, [])

  const handleDiscountRemoved = () => {
    removeCookie(PROMOCODE_COOKIE)
  }

  const otpDescription = t(
    'checkout:one-time-purchase-of-two-month-supply',
    'One time purchase of Two month supply'
  )
  const subscriptionDescription = t(
    'checkout:two-month-supply-shippied-every-two-months',
    'Two month supply shipped every two months'
  )

  const handleBeforeProceedToCheckout = (
    cart: CartResponse,
    proceedToCheckout
  ) => {
    if (enableConvertCartToSubscriptionPrompt) {
      const hasNonClearanceOTPItemInCart = cart?.items.some((item) =>
        item.subscription ? false : !isClearanceCartItem(item)
      )

      if (hasNonClearanceOTPItemInCart) {
        setPreCheckoutModal(
          () =>
            function PreModal({ isOpen, setIsOpen }) {
              return (
                <MakeItASubscriptionModal
                  cart={cart}
                  proceedToCheckout={proceedToCheckout}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
              )
            }
        )
        setShowConvertToSubModal(true)
        return
      }
    }

    proceedToCheckout()
  }

  return (
    <Drawer
      anchor="right"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      closeButton={false}
    >
      <>
        <CartContents
          setIsOpen={setIsOpen}
          bgColor="rust"
          color="cartTextColor"
          mx={['0.4rem', '0.875rem']}
          my={['0.25rem', '0.5625rem']}
          otpDescription={otpDescription}
          subscriptionDescription={subscriptionDescription}
          hiddenCustomizationOptions={hiddenCustomizationOptions}
          enablePromotions
          discountFilterFn={isValidDiscountCode}
          onDiscountRemoved={handleDiscountRemoved}
          enableAvailablePaymentMethods={enableAvailablePaymentMethods}
          vatConfig={vatConfig}
          idsToShowLowStockBadge={idsToShowLowStockBadge}
          onBeforeProceedToCheckout={handleBeforeProceedToCheckout}
          language={language}
        />
        {PreCheckoutModal && (
          <PreCheckoutModal
            isOpen={showConvertToSubModal}
            setIsOpen={setShowConvertToSubModal}
          />
        )}
      </>
    </Drawer>
  )
}
