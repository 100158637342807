import React, { ComponentType, FC, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'

interface ProductsSearchDrawerProps {
  isOpen: boolean
  onClose: () => void
}

const EmptyComponent = () => <></>

interface ProductsSearchDrawerContainerProps {
  isOpen: boolean
  onClose: () => void
}

const ProductsSearchDrawerContainer: FC<ProductsSearchDrawerContainerProps> = ({
  isOpen,
  onClose,
}) => {
  const [ProductsSearchDrawer, setProductsSearchDrawer] = useState<
    ComponentType<ProductsSearchDrawerProps>
  >(() => EmptyComponent)

  useEffect(() => {
    if (isOpen) {
      setProductsSearchDrawer(() =>
        dynamic(
          () =>
            import(
              'modules/products/components/PLPElements/ProductsFilterSection/ProductsSearchDrawer'
            ),
          { ssr: false }
        )
      )
    }
  }, [isOpen])

  return <ProductsSearchDrawer isOpen={isOpen} onClose={onClose} />
}

export default ProductsSearchDrawerContainer
