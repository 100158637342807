import { useMediaQuery } from '@chakra-ui/core'
import { useEffect, useState } from 'react'

import { useCurrentCountryCode } from './useCurrentCountryCode'

import { useFeatureFlag } from 'modules/optimizely/hooks/useFeatureFlag'

const getHeaderContainerHeight = () => {
  let height = 64
  if (typeof window !== 'undefined') {
    height =
      document.getElementById('top-nav-container')?.clientHeight || height
  }
  return height
}

function useHeaderHeight() {
  const [height, setHeight] = useState(getHeaderContainerHeight)
  const countryCode = useCurrentCountryCode()
  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const announcement = useFeatureFlag('ANNOUNCEMENT_TEXT', '')

  useEffect(() => {
    if (announcement || countryCode === 'AU') {
      setTimeout(() => {
        setHeight(getHeaderContainerHeight)
      }, 0)
    }
  }, [announcement, countryCode, isMobile])

  return height
}

export default useHeaderHeight
