//Customers should not be able to apply these code at checkout

import { SpecialCoupons } from '@pangaea-holdings/pangaea-checkout'

//And these codes shouldn't be visible to the customer when automatically applied
const SPECIAL_DISCOUNT_CODES: RegExp[] = [
  /^CANCEL/i,
  /^FREE_TRIAL/i,
  /HOLIDAY2020$/i,
  /^JANFLASH.*/i,
  /DISCOUNT_2021_50/,
  /DISCOUNT_2021_30/,
  /DISCOUNT_2021_20/,
  /HANDSTOGETHER/,
  /REFERRALCAMPAIGN/i,
]

const INVISIBLE_COUPONS = [
  'DISCOUNT_2021_20',
  'DISCOUNT_2021_30',
  'DISCOUNT_2021_50',
  'REFERRALCAMPAIGN',
]

export const isValidDiscountCode = (code: string): boolean => {
  const isInvalid = SPECIAL_DISCOUNT_CODES.some((re) => re.test(code))
  return !isInvalid
}

export const specialCoupons: SpecialCoupons = {
  invisibleCoupons: INVISIBLE_COUPONS,
}