export const FREE_TRIAL_COUNTRY_WHITELIST = [
  'US',
  'TH',
  'IE',
  'CH',
  'SE',
  'PR',
  'NO',
  'BE',
  'AT',
  'GB',
  'DE',
  'FR',
  'SG',
  'NL',
  'ES',
  'DK',
  'HK',
  'FI',
  'IS',
  'TW',
  'LU',
  'IL',
  'SA',
  'MY',
  'NZ',
  'IT',
  'PT',
  'PL',
  'JP',
  'CZ',
  'GR',
  'HU',
  'SI',
  'RS',
  'EE',
  'HR',
  'RO',
  'LV',
  'SK',
  'KR',
  'MX',
  'CA',
  'AU',
]
